import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';



export default function PageTitle  ({title, icon, sx})  {
    return (
        <Box display="flex" gap='8px' paddingBottom='24px' sx={sx}>
            {icon}
            <Typography
                fontSize='24px'
                fontWeight={600}>
                {title}
            </Typography>
        </Box>
    )
}