import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { handleNull } from "../helpers/handleNull";

const Circle = ({ children, diameter, color }) => {
  const circleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: diameter,
    height: diameter,
    borderRadius: "50%",
    border: "none",
    backgroundColor: color,
  };

  return <div style={circleStyle}>{children}</div>;
};

export default function CircleNumber({ rank, label, diameter }) {
  const innerDiameter = diameter - 24;
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: { xs: "8px", sm: "32px" }, // Responsive gap: 16px for small screens, 32px for larger screens
        flex: "1 0 0",
      }}
    >
      {" "}
      <Circle
        diameter={diameter.toString() + "px"}
        color={theme.palette.ranks[rank]}
      >
        <Circle
          diameter={innerDiameter.toString() + "px"}
          color={theme.palette.primary[700]}
        >
          <Typography fontSize={rank === null ? "18px" : "48px"} color="#FFF">
            {handleNull(rank)}
          </Typography>
        </Circle>
      </Circle>
      <div>
        <Typography fontSize="16px">{label}</Typography>
      </div>
    </Box>
  );
}
