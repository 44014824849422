import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { AppBar, Avatar, IconButton, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SettingsIcon = ({ onClick }) => {
  return (
    <IconButton edge="start" color="white" onClick={onClick}>
      <Avatar
        src="/public/icons/settings-01.svg"
        alt="Settings"
        style={{ height: "20px", width: "auto" }}
      />
    </IconButton>
  );
};

const SettingsDialog = ({ settings }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SettingsIcon
        id="settings-button"
        aria-controls={open ? "settings-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: "5px" }}
        slotProps={{
          paper: {
            style: {
              width: "250px",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "settings-button",
        }}
      >
        {settings.view === "admin" && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/admin"
            divider={true}
          >
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon
                fontSize="small"
                sx={{ color: theme.palette.highlight.main }}
              />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} component={Link} to="/bond/issuer-view">
          <ListItemIcon>
            <BubbleChartOutlinedIcon
              fontSize="small"
              sx={{ color: theme.palette.highlight.main }}
            />
          </ListItemIcon>
          <ListItemText>Bond Ranking</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/logout">
          <ListItemIcon>
            <LogoutOutlinedIcon
              fontSize="small"
              sx={{ color: theme.palette.highlight.main }}
            />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const BondRankingNavigation = ({ view }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return (
    <Box
      mt={{ xs: "16px", sm: "64px" }}
      mx={{ xs: "16px", sm: "32px" }}
      mb={{ xs: "16px", sm: "32px" }}
      sx={{ borderBottom: 1, borderColor: "divider" }}
    >
      <Tabs value={value} onChange={handleChange} variant="scrollable">
        <Tab label="IssuerView" value={"/bond/issuer-view"} />
        <Tab label="BondExplorer" value={"/bond/bond-explorer"} />
        <Tab label="BondRanking" value={"/bond/bond-ranking"} />
        {view === "admin" && (
          <Tab label="BankRanking" value={"/bond/bank-ranking"} />
        )}
      </Tabs>
    </Box>
  );
};

export default function Navbar({ settings }) {
  const theme = useTheme();

  const location = useLocation();
  // Array of routes where the navbar should be hidden
  const hiddenRoutes = [
    "/login",
    "/logout",
    "/register",
    "/not-allowed",
    "/not-found",
    "/session-expired",
  ];
  // Check if the current route is in the hiddenRoutes array
  const shouldHideNavbar = hiddenRoutes.includes(
    `/${location.pathname.split("/")[1]}`
  );

  if (shouldHideNavbar) {
    return null; // Do not render the navbar on the login page
  }
  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          marginBottom: "32px",
          backgroundColor: theme.palette.primary.main,
          width: "100%", // Ensure it takes the full width
          left: 0, // Ensure it's aligned to the left
          right: 0, // Ensure it's aligned to the right
        }}
      >
        <Toolbar display={"flex"} sx={{ justifyContent: "space-between" }}>
          <Box>
            <img
              src="/public/logos/logo_dark_mode.svg"
              alt="DebtRay"
              style={{ height: "32px" }}
            />
          </Box>
          <div padding={"10px"}>
            <SettingsDialog settings={settings} />
          </div>
        </Toolbar>
      </AppBar>
      {location.pathname !== "/admin" && (
        <BondRankingNavigation view={settings.view} />
      )}
    </>
  );
}
