import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import BoxTitle from "../headers/BoxTitle";

const Header = ({ title, nRows, onButtonClick, unit = "Issues" }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingRight="16px"
      paddingLeft="16px"
      marginBottom="16px"
      sx={{
        flexWrap: "wrap", // Allow items to wrap to the next line if they don't fit
      }}
    >
      <Box
        display="flex"
        gap="8px"
        alignItems="center"
        sx={{ flexWrap: "wrap" }}
      >
        <BoxTitle title={title} paddingBottom="0" />
        <Box
          backgroundColor={theme.palette.primary[50]}
          border={`1px solid ${theme.palette.primary[700]}`}
          padding="3px"
          paddingLeft="8px"
          paddingRight="8px"
          borderRadius={8}
          sx={{
            marginTop: { xs: "0px", sm: "0" }, // Add top margin for small screens to prevent overlap
          }}
        >
          <Typography fontSize="12px" color={theme.palette.primary[700]}>
            {`${nRows} ${unit}`}
          </Typography>
        </Box>
      </Box>

      <Button
        onClick={onButtonClick}
        sx={{
          marginTop: { xs: "8px", sm: "0" }, // Add margin top for small screens
          width: { xs: "100%", sm: "auto" }, // Button takes full width on small screens
        }}
      >
        <Typography color="#344054">Customize Columns</Typography>
      </Button>
    </Box>
  );
};

const CustomizeColumns = ({
  onClose,
  anchorEl,
  columns,
  onToggle,
  onReset,
}) => {
  const theme = useTheme();

  const ShowList = () => {
    return (
      <List>
        {columns.map((x) => (
          <React.Fragment key={x.headerName}>
            <ListItem>
              <ListItemText>
                <Box display="flex" justifyContent="space-between">
                  {x.headerName}
                  <Switch
                    className="switch"
                    checked={x.display}
                    onChange={onToggle(x.headerName)}
                  />
                </Box>
              </ListItemText>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    );
  };

  const ShowReset = () => {
    return (
      <MenuItem onClick={onReset}>
        <ListItemIcon>
          <RestartAltIcon
            fontSize="small"
            sx={{ color: theme.palette.highlight.main }}
          />
        </ListItemIcon>
        <ListItemText>Reset</ListItemText>
      </MenuItem>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      sx={{ marginTop: "5px" }}
      slotProps={{
        paper: {
          style: {
            width: "300px",
          },
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <ShowList />
      <Divider />
      <ShowReset />
    </Menu>
  );
};

export const DataTable = (props) => {
  // Styling via theme (no sx props)
  const {
    title,
    columns,
    data,
    onRowClick,
    width = "100%",
    pageSize = 10,
    initialSorting,
    unit = "Issues",
  } = props;
  const nRows = data.length;
  const hidePagination = nRows <= pageSize;

  // disable column menu
  const adaptColumns = (xs) => {
    return xs.map((x) => ({ ...x, disableColumnMenu: true, flex: 1 }));
  };
  const imputeDisplay = (xs) => {
    return xs.map((x) => ({
      ...x,
      display: x.display === undefined ? true : x.display,
    }));
  };
  const filterDisplay = (xs) => {
    return xs.filter((x) => x.display);
  };
  const defaultColumns = imputeDisplay(adaptColumns(columns));

  const [displayedColumns, setDisplayedColumns] = useState(defaultColumns);
  const [anchorEl, setAnchorEl] = useState(undefined);

  /* Handlers for button click*/
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* Handlers within menu*/
  const handleToggle = (headerName) => () => {
    const index = displayedColumns.findIndex(
      (item) => item.headerName === headerName
    );
    const newDisplayedColumns = Array.from(displayedColumns);
    newDisplayedColumns[index] = {
      ...newDisplayedColumns[index],
      display: !newDisplayedColumns[index].display,
    };
    setDisplayedColumns(newDisplayedColumns);
  };
  const handleReset = () => {
    setDisplayedColumns(defaultColumns);
  };

  /* Handler for row click on table*/
  const handleRowClick = (params) => {
    onRowClick(params.row.isin);
  };

  return (
    <Box
      width={width}
      paddingTop={{ xs: "16px", sm: "24px" }}
      border="1px solid #EAECF0"
      borderRadius={3}
    >
      {/* Top level information */}
      <Header
        title={title}
        nRows={nRows}
        unit={unit}
        onButtonClick={handleButtonClick}
      />

      {/* Costumize Columns Menu */}
      <CustomizeColumns
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        columns={displayedColumns}
        onToggle={handleToggle}
        onReset={handleReset}
      />

      {/* Actual Table */}
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <DataGrid
          rows={data}
          columns={filterDisplay(displayedColumns).map((col) => ({
            ...col,
            minWidth: 150, // Optional: Set a minimum width to prevent columns from becoming too narrow
            flex: 1, // This allows the column to grow/shrink to fit its content
            renderCell: (params) => (
              <Box sx={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {params.value}
              </Box>
            ),
          }))}
          onRowClick={handleRowClick}
          initialState={{
            sorting: {
              sortModel: initialSorting ? [initialSorting] : null,
            },
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pageSizeOptions={[pageSize]}
          disableRowSelectionOnClick
          hideFooterPagination={hidePagination}
          slotProps={{
            row: {
              style: { cursor: "pointer" },
            },
          }}
        />
      </Box>
    </Box>
  );
};
