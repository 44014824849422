import { Typography } from "@mui/material";

export const IdTitle = ({ id }) => {
  return (
    <Typography
      fontSize={{ xs: "20px", sm: "24px" }}
      paddingBottom={{ xs: "12px", sm: "24px" }}
      sx={{
        pt: { xs: "16px", sm: "0px" },
      }}
    >
      {id}
    </Typography>
  );
};
