import Typography from "@mui/material/Typography";

export const SectionTitle = ({ title }) => {
  return (
    <Typography
      fontSize={{ xs: "16px", sm: "20px" }} // Smaller font for mobile, default for desktop
      paddingBottom={{ xs: "12px", sm: "24px" }} // Less padding on mobile
      paddingTop={{ xs: "16px", sm: "24px" }} // Adjust padding for responsiveness
    >
      {title}
    </Typography>
  );
};
