import Box from "@mui/material/Box";
import * as React from "react";

export default function GraphBox(props) {
  const {
    children,
    width,
    height,
    paddingTop = "24px",
    paddingBottom = "24px",
    flex = "1",
    marginBottom,
  } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width}
      height={height}
      alignContent="left"
      border="1px solid #EAECF0"
      borderRadius={3}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingRight="16px"
      paddingLeft="16px"
      marginBottom={marginBottom}
      flex={flex}
    >
      {children}
    </Box>
  );
}
