import { Box } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdTitle } from "../../components/headers/IdTitle";
import PageTitle from "../../components/headers/PageTitle";
import { SectionTitle } from "../../components/headers/SectionTitle";
import { useSharedIsin } from "../../contexts/SharedIsin";
import fetchWithRefresh from "../../helpers/api";
import useHandleError from "../../helpers/handleError";
import { CompanySummary } from "./CompanySummary";
import CompanyTable from "./CompanyTable";
import { RecentIssue } from "./RecentIssue";
import SearchCompany from "./SearchCompany";

export default function Company({ defaultCompany }) {
  const { isin, setIsin } = useSharedIsin();
  const [company, setCompany] = useState(defaultCompany);
  const [data, setData] = useState(undefined);
  const [searchCandidates, setSearchCandidates] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const handleError = useHandleError();

  useEffect(() => {
    fetchWithRefresh("/api/bond_ranking/search/company")
      .then((resp) => resp.json())
      .then(setSearchCandidates)
      .catch((error) => {
        console.log("Fetch error company search", error);
        setError(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = () => {
      fetchWithRefresh(
        `/api/bond_ranking/company/?company=${encodeURIComponent(company)}`
      )
        .then((resp) => resp.json())
        .then(setData)
        .catch((error) => {
          console.log("Fetch error company", error);
          setError(error);
        });
    };
    if (![null, undefined].includes(company)) {
      fetchData();
    }
  }, [company]);

  const handleRowClick = (isin) => {
    setIsin(isin);
    navigate("/bond/bond-ranking");
  };

  handleError(error);

  return (
    <div className="page-container">
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: {
            xs: "0px",
            sm: "12px",
          },
        }}
      >
        <PageTitle
          title={"IssuerView"}
          sx={{ paddingBottom: { xs: "12px", sm: "24px" } }}
        />
        {searchCandidates && (
          <SearchCompany
            candidates={searchCandidates}
            onSearchResultClick={setCompany}
          />
        )}
      </Box>

      {data && (
        <>
          <IdTitle id={data.id} />
          <Box
            className="row-container"
            marginBottom={"24px"}
            sx={{
              mt: { xs: "0px", sm: "0px" },
              display: "flex", // Ensure the container is using flexbox
              //   flexDirection: { xs: "column", sm: "row" }, // Stack elements vertically on small screens
              width: "100%", // Ensure the container takes full width
            }}
          >
            <CompanySummary
              title={"Company Statistics"}
              data={data.statistics}
              flex={"1 1 0"}
            />
            <Box flex={"2 1 0"} display={{ xs: "none", sm: "block" }}></Box>
            {/* This is likely to remain as a spacer */}
          </Box>

          {/* Set the CompanyTable to take full width */}
          <CompanyTable
            data={data.data}
            onRowClick={handleRowClick}
            sx={{ width: "100%" }} // This ensures full width
          />

          <SectionTitle title={"Most Recent Issue"} />
          <RecentIssue data={data.data} />
          <Box padding="32px"></Box>
        </>
      )}
    </div>
  );
}
