import React, { Fragment } from "react";
import Search from "../../components/search/Search";
import SearchResultItem from "../../components/search/SearchResultItem";

export default function SearchCompany({ candidates, onSearchResultClick }) {
  const ShowList = ({ list }) => {
    return (
      <>
        {list.map((item, index) => (
          <Fragment key={index}>
            {item}
            <br />
          </Fragment>
        ))}
      </>
    );
  };

  const renderResultItem = (result) => {
    return (
      <SearchResultItem
        primary={result.parentCompany}
        secondary={<ShowList list={result.issuer} />}
      />
    );
  };

  return (
    <Search
      candidates={candidates}
      onSearchResultClick={onSearchResultClick}
      searchFields={["parentCompany", "issuer"]}
      arrayType={["issuer"]}
      id={"parentCompany"}
      renderResultItem={renderResultItem}
      minHeight={50}
      width={{
        xs: "100%",
        // sm: '50%',
        sm: "320px",
      }}
    />
  );
}
