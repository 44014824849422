import { Box } from "@mui/material";
import * as React from "react";
import CircleWithRank from "../../components/CircleWithRank";
import GraphBox from "../../components/GraphBox";
import BoxTitle from "../../components/headers/BoxTitle";
import Info from "../../components/Info";
import { InfoTable } from "../../components/tables/InfoTable";
import { InfoDebtRayBondRanking } from "./InfoTexts";

export function BondMasterData({ data, flex }) {
  //const rows = create_rows(data)
  const rows = [
    { key: "Issuer", value: data.issuer },
    { key: "ISIN Number", value: data.isin },
    { key: "Bond Type", value: data.bondType },
    { key: "Industry", value: data.industry },
    { key: "Industry Group", value: data.industryGroup },
    {
      key: "Volume",
      value: `${data.amountIssued} m ${data.principalCurrency}`,
    },
    { key: "Coupon", value: `${data.coupon.toFixed(3)} %` },
    { key: "Life Span", value: `${data.lifeSpan} years` },
    { key: "Announcement Date", value: data.firstAnnouncementDate },
    { key: "Issue Date", value: data.issueDate },
    { key: "Maturity Date", value: data.maturity },
  ];
  return (
    <GraphBox paddingBottom="16px" paddingTop="16px" flex={flex}>
      <BoxTitle
        title={"Bond Master Data"}
        paddingBottom={{ xs: "8px", sm: "16px" }}
      />
      <InfoTable rows={rows} />
    </GraphBox>
  );
}

export function DebtRayRanking({ data, info, flex }) {
  const title = "BondRanking";
  return (
    <GraphBox paddingBottom="16px" flex={flex}>
      <Box display="flex" alignItems="flex-start">
        <BoxTitle
          paddingBottom="16px"
          title={title}
          icon={<img src="/public/icons/trophy-01.svg" alt="Tropy" />}
        />
        {info && <Info title={title} info={info} mt="-7px" />}
      </Box>

      <Box
        sx={{
          paddingTop: { xs: "16px", md: "32px" },
          paddingBottom: { xs: "16px", md: "32px" },
          paddingLeft: { xs: "32px", md: "64px" },
          paddingRight: { xs: "32px", md: "64px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "24px", md: "32px" },
          flexWrap: "wrap",
          alignItems: { xs: "center", md: "flex-end" },
        }}
      >
        <CircleWithRank
          rank={data.overallRank}
          label={"BondRank"}
          diameter={148}
        />
        <CircleWithRank
          rank={data.pricingRank}
          label={"PricingRank"}
          diameter={124}
        />
        <CircleWithRank
          rank={data.timingRank}
          label={"TimingRank"}
          diameter={124}
        />
      </Box>
    </GraphBox>
  );
}

export default function BasicOverview({ data }) {
  return (
    <>
      <div className="row-container">
        <BondMasterData data={data.data} flex={"1 1 0"} />
        <DebtRayRanking
          data={data.data}
          info={<InfoDebtRayBondRanking />}
          flex={"2 1 0"}
        />
      </div>
    </>
  );
}
