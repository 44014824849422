import * as React from "react";
import GraphBox from "../../components/GraphBox";
import BoxTitle from "../../components/headers/BoxTitle";
import { InfoTable } from "../../components/tables/InfoTable";
import { roundWithNull } from "../../helpers/handleNull";

const format = (s) => {
  return s.toLocaleString("en-US", {
    maximumFractionDigits: 0,
    useGrouping: true,
  });
};

export const CompanySummary = ({ data, title, flex }) => {
  const rows = [
    { key: "Number of Bonds", value: data.numberOfBonds },
    { key: "Total Volume", value: `${format(data.totalVolume)} m` },
    { key: "Coupon Range", value: `${data.couponMin} -  ${data.couponMax} %` },
    {
      key: "Life Span Range",
      value: `${data.lifeSpanMin} -  ${data.lifeSpanMax} years`,
    },
    { key: "Avg. BondRank", value: `${roundWithNull(data.avgOverallRank, 1)}` },
    {
      key: "Avg. PricingRank",
      value: `${roundWithNull(data.avgPricingRank, 1)}`,
    },
    {
      key: "Avg. TimingRank",
      value: `${roundWithNull(data.avgTimingRank, 1)}`,
    },
  ];

  return (
    <GraphBox
      paddingBottom={{ xs: "16px", sm: "24px" }}
      paddingTop={{ xs: "16px", sm: "24px" }}
      flex={flex}
      sx={{
        width: { xs: "100%", sm: "auto" }, // Ensure full width on xs screens
        marginLeft: "auto", // Center the content if needed
        marginRight: "auto", // Center the content if needed
      }}
    >
      <BoxTitle title={title} paddingBottom={{ xs: "8px", sm: "16px" }} />
      <InfoTable
        rows={rows}
        sx={{
          width: "100%", // Ensure table also takes full width
        }}
      />
    </GraphBox>
  );
};
